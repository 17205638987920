/* eslint-disable */
import React, { useState, useContext, useEffect } from "react"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Projects from "../sections/projects"
import SelectedInterests from "../sections/selectedInterests"
import UserContext from "../context/UserContext"
import DefaultLayout from "../layouts/defaultLayout"

let wizardData = {}

const AddProject = ({ location, data }) => {
  const context = useContext(UserContext)

  // Updating wizard data with context data
  wizardData = context.wizardData

  // If localStore is availible it will use that
  useEffect(() => {
    const lsWizardData = JSON.parse(localStorage.getItem("wizardData"))
    if (lsWizardData) {
      wizardData = lsWizardData
    }
  })

  // SELECTED CARDS STATE
  const [selectedProjects, setSelectedProjects] = useState([])
  const [selectedProjectsData, setselectedProjectsData] = useState([])

  // change handlers for icons
  const changeProjectIcons = selectedProjects => {
    var icons = []
    selectedProjects.forEach(element => {
      if (uniqueAllProjects[element] != null) {
        icons.unshift(uniqueAllProjects[element])
      }
    })
    setselectedProjectsData(icons)
  }

  // OnClick button handlers
  function onProjectListChange(selectedProjects) {
    setSelectedProjects(selectedProjects)
    changeProjectIcons(selectedProjects)
  }
  function getSelectedProjects() {
    if (selectedProjects != null) {
      window.alert("Selected Projects: " + selectedProjects)
    }
  }

  if (data == null || location.state == null) {
    return (
      <DefaultLayout>
        <Link to="/">Go to home</Link>
      </DefaultLayout>
    )
  }

  // ALL PROJECTS DATA:
  const allProjects = data.allContentfulProjectNew.nodes

  // PROJECTS
  // TODO: Add selecting state here
  // Grabbing correct data corresponding to each question

  // Removing duplicates
  const uniqueAllProjects = Array.from(new Set(allProjects.map(a => a.id))).map(
    id => {
        return allProjects.find(a => a.id === id && !location.state.doneProjects.includes(id))
    }
  )

  // Sorting array according to compare function
  uniqueAllProjects.sort(compare)
  // console.log(uniqueAllProjects)

  // RETURN
  // this is returned if the user FILLS OUT the wizard PROPERLY

  return (
    <div>
      <SelectedInterests
        selectedProjectsData={selectedProjectsData}
        selectedQuickActionData={[]}
        type="add"
        email={location.state.email}
      />
      <DefaultLayout>
        <SEO title="Add a Project" />
        <div>
          <h1
            style={{
              fontWeight: "300",
              textAlign: "center",
              fontSize: "1.5rem",
            }}
          >
            <b>Select more projects to add!</b>
          </h1>
        </div>
        <br />
        {/* <hr style={{ background: "#01b76b", width: "75%", height: "5px" }} /> */}
        <div>
            <br/>
          <Projects
            selected={selectedProjects}
            onListChange={onProjectListChange}
            getSelected={getSelectedProjects}
            content={uniqueAllProjects}
            type="add"
          />
        </div>
      </DefaultLayout>
    </div>
  )
}

function compare(a, b) {
  if (a.priority < b.priority) {
    return -1
  }
  if (a.priority > b.priority) {
    return 1
  }
  return 0
}

export const query = graphql`
  query AllNewProjects {
    allContentfulProjectNew {
      nodes {
        id
        name
        slug
        description {
          json
        }
        icon {
          file {
            url
          }
        }
        takeaways {
          json
        }
        tasks {
          id
          name
          description {
            json
          }
          toDo {
            json
          }
        }
        why {
          json
        }
        toConsider {
          id
          name
          toDo {
            json
          }
        }
        banner {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default AddProject
